<template>
  <button
    :type="type"
    :class="classes"
    :style="style"
    v-on="$listeners"
  >
    <slot/>
  </button>
</template>

<script>
import { convertToUnit } from '@/utils';
var num = 1;

export default {
  name: 'Btn',
  props: {
    block: Boolean,
    small: Boolean,
    dark: Boolean,
    color: String,
    backgroundColor: String,
    width: [ String, Number ],
    height: [ String, Number ],
    type: {
      type: String,
      default: 'button'
    }
  },
  data() {
    return {
      internal: !!this.value
    }
  },
  computed: {
    classes() {
      return {
        btn: true,
        'btn--block': this.block,
        'btn--small': this.small,
        'btn--dark': this.dark
      };
    },
    style() {
      return {
        width: convertToUnit( this.width ),
        height: convertToUnit( this.height ),
        backgroundColor: this.backgroundColor,
        color: this.color
      }
    }
  },
  watch: {
    value( value ) {
      this.internal = !!value;
    }
  }
}
</script>

<style>
.btn {
  border: none;
  color: #107bc1;
  background-color: #253f8e;
  font-weight: bolder;
  text-align: center;
  text-decoration: none;
  font-size: 22px;
  margin: 0 5px 5px 5px;
  position: relative;
  box-shadow: 5px 5px 8px black;
  cursor: pointer;
}

.btn:active, .btnSelected {
    color: #107bc1;
    background-color: white;
    transform: translateY(2px);
}

.btn.btn--small {
  font-size: 16px;
}
.btn.btn--block {
  width: 100%;
}
.btn.btn--dark {
  color: white;
}
.btn > span {
  position: relative;
  z-index: 1;
}
</style>
