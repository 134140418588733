<template>
  <div
    class="field-input"
    :class="{ focused, error }"
    @click="$emit('click',$event)"
    @mousedown="$emit('mousedown',$event)"
    @touchstart="$emit('touchstart',$event)"
  >
    <div tabindex="0" class="field-input-wrappper">
      <slot/>
    </div>
    <transition name="slide-y">
      <div v-if="hint" class="field-input-hint">
        {{ hint }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    value: null,
    focused: Boolean,
    validateOnChange: Boolean,
    rules: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    error: false,
    hint: ''
  }),
  watch: {
    value() {
      this.validateOnChange && this.validate();
    }
  },
  methods: {
    validate() {
      this.error = false;
      this.hint = '';
      this.rules.some( rule => {
        let result = typeof rule === 'function' ? rule( this.value ) : rule;
        if ( typeof result === 'string' || result === false ) {
          this.error = true;
          this.hint = result || '';
          return true;
        }
        return false;
      });
      return !this.error;
    },
    hasError() {
      return this.validate() ? false : this.hint;
    }
  }
}
</script>

<style>
.field-input {
  position: relative;

}
.field-input > .field-input-wrappper {
  display: flex;
  align-items: center;
  background-color: #fff;
}
.field-input.focused > .field-input-wrappper {
  outline: 0;
}
.field-input.error > .field-input-wrappper {
  box-shadow: 0 0 0 2px #f85050;
}
.field-input > .field-input-wrappper > span {
  padding: 0 12px;
  font-size: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1 auto;
  line-height: 1.25;
}
.field-input > .field-input-wrappper > input {
  -webkit-appearance: none;
  appearance: none;
  font-size: 80%;
  background-color: transparent;
  border: 0;
  font-size: 22px;
  color: #606060;
  height: 50px;
}
.field-input > .field-input-wrappper > input:focus {
  border: 0;
  outline: 0;
}
.field-input > .field-input-wrappper > .placeholder,
.field-input > .field-input-wrappper > input::placeholder {
  color: #b3b3b3;
  font-size: 80%;
}
.field-input > .field-input-hint {
  position: absolute;
  top: 100%;
  left: 0; right: 0;
  padding: 5px 24px;
  color: #ffafaf;
  font-size: 0.9em;
  font-weight: normal;
}
</style>
