<template>
  <label
    ref="checkbox"
    class="checkbox"
    :class="{ error }"
    :for="computedID"
  >

    <slot>
      {{ label }}
    </slot>

    <input
      type="checkbox"
      :id="computedID"
      :checked="internal"
      @keydown="onKeypress"
      @change="onChange"
    />

    <span class="checkmark"/>

  </label>
</template>

<script>
var num = 1;

export default {
  name: 'Checkbox',
  props: {
    value: null,
    label: String,
    error: Boolean
  },
  data() {
    return {
      internal: !!this.value,
      computedID: `checkbox-${ Date.now() + ( num++ )}`
    }
  },
  watch: {
    value( value ) {
      this.internal = !!value;
    }
  },
  methods: {
    onChange(e) {
      this.$emit( 'input', e.target.checked );
    },
    onKeypress(e) {
      if ( e.which == 13 ) {
        e.preventDefault();
        this.internal = !this.internal;
        this.$emit( 'input', this.internal );
      }
      this.$emit( 'keydown', e );
    },
    focus() {
      this.$refs.checkbox && this.$refs.checkbox.focus();
    }
  }
}
</script>

<style>
.checkbox {
  flex: 0 0;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 47px;
  height: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox > input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkbox > .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: white;
  transition: box-shadow .3s cubic-bezier( 0.25, 0.8, 0.5, 1 );
  box-shadow: 0 0 0 0 rgba(204,204,204,0);
}
.checkbox:hover > input[type="checkbox"] ~ .checkmark {
  background-color: #f9f9f9;
}
.checkbox > input[type="checkbox"]:focus ~ .checkmark {
  box-shadow: 0 0 0 3px rgba(204,204,204,.5);
}
.checkbox.error > .checkmark {
  box-shadow: 0 0 0 3px #f85050;
}
.checkbox > .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox > input[type="checkbox"]:checked ~ .checkmark:after {
  display: block;
}
.checkbox > .checkmark:after {
  left: 11px;
  top: 3px;
  width: 6px;
  height: 16px;
  border: solid #CCC;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
