import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'pt': {

        imageExpert: "images/Logo_Expertos_pt.svg",
        imageEducate: "images/educatePet_pt.svg",
        imageFooter: "images/footer_pt.svg",

        tab1: "Reserve <br>o seu lugar",
        tab2: "Perguntas <br> frequentes",
        tab3: "Cidades",
        name:'Nome do tutor',
        surname:'Apelido do tutor',
        email:'E-mail',
        phone:'Telefone',
        dogName:'Nome do cão',
        dogAge:'Idade do cão',
        dogWeight:"Peso do cão (Kg)",
        clinic:"Clínica veterinária habitual",
        city:"Seleccione a cidade a Masterclass",
        turnType:"Seleccionar o horário*",
        turn:"Seleccione o turno",
        turnInfo:"* Escolha o horário de acordo com as características do seu cão (a Masterclass adaptar-se-á ao tipo de cães que irão assistir): cachorros; cães adultos; ou seniores (cães com mais de 7 anos de idade). Inscrição permitida para um só cão por tutor. Sujeito a alterações de acordo com a disponibilidade.",
        privacy:"A Elanco vai processar os seus dados pessoais de acordo com a política de privacidade disponível em <a href=\"https://www.privacy.elanco.com\" target=\"blank\">www.privacy.elanco.com</a>",
        reserve:"Reservar!",
        waitingList:"<strong>Junte-se à lista de espera</strong><br>e se um lugar se tornar disponível, entraremos em contacto consigo.",
        btnWaiting:"¡Junte-se à lista!",
        footer1: "Elanco e a barra diagonal são marcas registadas da Elanco ou suas filiais.",
        footer2: "©2022 Elanco Animal Health, Inc. ou suas filiais. PM-PT-22-0118",
        directions: "Como lá chegar",

        placesWaitingList: "lista de espera",
        place: "lugar",
        places: "lugares",

        inscriptionTitle: "Obrigado pela sua inscrição.",
        inscriptionMessage: "No caso de querer cancelar ou alterar a sua reserva, contacte-nos através do e-mail: <a href=\"mailto://EspecialistasEmAntiparasitarios@elancoah.com\">EspecialistasEmAntiparasitarios@elancoah.com</a>.",
        close: "Fechar",

        waitingListTitle: "Obrigado por se inscrever para a lista de espera.",
        waitingListMessage: "Se um lugar se tornar disponível, entraremos em contacto consigo.",

        faq1mainheader: "FAQ's:",
        faq2mainheader: "Recomendações:",
        faq3mainheader: "Regras:",

        faq1header: 'O que é necessário para assistir à Masterclass?',
        faq1message1: 'Preencher o formulário de inscrição que encontrará na secção "Reserve o seu lugar" desta página.',
        faq1message2: 'Comparecer na data, hora e local designados para a masterclass.',
        faq1message3: 'Traga consigo ou mostre no seu telemóvel o e-mail de confirmação.',
        faq1message4: 'É recomendável trazer o Boletim Sanitário do seu cão, para o caso de suceder alguma eventualidade.',
        faq2header: 'Há algum requisitio prévio para inscrição na Masterclass?',
        faq2message1: 'As sessões são em grupo, e por isso é recomendável que o seu cão seja sociável, tanto com pessoas, como com outros cães.',
        faq2message2: 'Não é necessário que o tutor tenha conhecimentos sobre adestramento canino ou o cão treino prévio.',
        faq3header: 'Quanto tempo dura a Masterclass?',
        faq3message1: 'Cada sessão tem uma duração máxima de 30 minutos.',
        faq4header: 'O que vou aprender?',
        faq4message1: 'Serão descrito protocolos gerais sobre a correcta convivência com os cães e o seu bem-estar, assim como algumas noções básicas de treino canino.',
        faq5header: 'Quem orientará a Masterclass?',
        faq5message1: 'Profissionais da Educação Canina de diferentes entidades, com uma ampla experiência no tema.',
        faq6header: 'Posso anular ou alterar uma reserva?',
        faq6message1: 'Se necessita cancelar ou alterar a sua reserva, envie um e-mail para <a href=\"mailto:EspecialistasEmAntiparasitarios@elancoah.com">EspecialistasEmAntiparasitarios@elancoah.com</a> confirmaremos a anulação ou verificaremos se existem lugares disponíveis noutros horários.',
        faq7header: 'Tem dúvidas sobre a Masterclass?',
        faq7message1: 'Contacte-nos através do telefone <b><a href=\"tel://+351911551354\">+351 911 551 354</a></b> ou envie-nos um e-mail para <b><a href=\"mailto://villa.dtc@gmail.com\">villa.dtc@gmail.com</a></b> e tentaremos esclarê-lo.',
        recommendation1:'Leve roupa e calçado cómodos.',
        recommendation2: 'Opcional: Leve alguns biscoitos ou algum tipo de recompensa que o seu cão possa gostar.',
        obligation1: 'Os cães devem estar sempre acompanhados pelo seu tutor(a).',
        obligation2: 'Os cães devem ser conduzidos pelos seus tutores com equipamento de passeio cómodo e adequado, para que não provoquem nenhum tipo de lesão ao animal.',
        obligation3: 'As trelas de estrangulamento, paus, castigos, etc., não serão permitidos',
        obligation4: 'Qualquer sinal de violência ou de mau trato perante qualquer dos animais presentes na atividade, pode implicar a expulsão da mesma.',

        required_name: "Deve introduzir o seu nome",
        required_surname: "Deve introduzir o seu apelido",
        required_email: "Deve introduzir o seu e-mail",
        required_phone: "Deve introduzir o seu número de telefone",
        required_dogName: "Deve introduzir o nome do seu cão",
        required_dogWeight: "Deve introduzir o peso do seu cão",
        required_dogAge: "Deve introduzir a idade do seu cão",
        required_clinic: "Deve entrar na sua clínica habitual",
        required_city: "Deve seleccionar a cidade",
        required_turn_type: "Deve seleccionar o tipo de turno desejado",
        required_turn: "Deve seleccionar o turno desejado",
        
        type1: "Cachorros",
        type0: "Adultos",
        type2: "Sénior (>7)",

        unknown_error: "Houve um erro inesperado.<br>Por favor tente novamente mais tarde.",
        location_unknown: "Local será confirmado em breve"
    },
    'es': {
       
        imageExpert: "images/Logo_Expertos_es.svg",
        imageEducate: "images/educatePet_es.svg",
        imageFooter: "images/footer_es.svg",

        tab1: "Reserva <br>tu Plaza",
        tab2: "Preguntas <br>frecuentes",
        tab3: "Ciudades",
        name:'Nombre del propietario',
        surname:'Apellidos del propietario',
        email:'E-mail',
        phone:'Teléfono',
        dogName:'Nombre del perro',
        dogAge:'Edad del perro',
        dogWeight:"Peso del perro (Kg)",
        clinic:"Clínica veterinaria habitual",
        city:"Selecciona la ciudad de la masterclass",
        turnType:"Selecciona el tipo de turno*",
        turn:"Selecciona el horario",
        turnInfo:"* Elige el turno en función de las características de tu perro: mixto (la masterclass se adaptará según la tipología de perros asistentes), cachorros, o senior (perros de 7 años en adelante).<br>Inscripción permitida para un solo perro por propietario.<br>Sujeto a cambios según disponiblidad. ",
        privacy:"Elanco procesará mi información personal de acuerdo a la politica de privacidad disponible en <a href=\"https://www.privacy.elanco.com\" target=\"blank\">www.privacy.elanco.com</a>",
        reserve:"¡Reservar!",
        waitingList:"<strong>Apúntate a la lista de espera</strong><br>y en caso de que se libere alguna plaza, nos pondremos en contacto contigo.",
        btnWaiting:"¡Apúntate a la lista!",
        footer1: "Elanco y la barra diagonal son marcas registradas de Elanco o sus filiales.",
        footer2: "©2022 Elanco Animal Health, Inc. o sus filiales. PM-ES-22-0191",
        directions: "Cómo llegar",

        placesWaitingList: "lista de espera",
        place: "plaza",
        places: "plazas",

        inscriptionTitle: "Gracias por inscribirte.",
        inscriptionMessage: "En caso de querer cancelar o cambiar una reserva, contáctanos a: <a href=\"mailto://ExpertosEnAntiparasitarios@elancoah.com\">ExpertosEnAntiparasitarios@elancoah.com</a>.",
        close: "Cerrar",

        waitingListTitle: "Gracias por inscribirte en lista de espera.",
        waitingListMessage: "En caso de quedar libre alguna plaza, nos pondremos en contacto contigo.",

        faq1mainheader: "FAQ's:",
        faq2mainheader: "Recomendaciones:",
        faq3mainheader: "Obligaciones:",

        faq1header: '¿Qué necesito para asistir a la masterclass?',
        faq1message1: 'Rellenar el formulario de inscripción que encontrarás en la sección “Reserva tu plaza” de esta web.',
        faq1message2: 'Acudir a la fecha, hora y lugar designados para la celebración de la masterclass.',
        faq1message3: 'Traer o enseñar en tu dispositivo móvil el correo de confirmación.',
        faq1message4: 'Es recomendable traer la cartilla o pasaporte de tu perro por si sucediese una eventualidad.',
        faq2header: '¿Hay algún requisito previo para inscribirse en la masterclass?',
        faq2message1: 'Las sesiones son grupales, por lo que es recomendable que tu perro sea sociable tanto con personas como con otros perros.',
        faq2message2: 'No es necesario que el tutor o el perro tengan nociones sobre entrenamiento canino.',
        faq3header: '¿Cuánto tiempo dura la masterclass?',
        faq3message1: 'Cada sesión tiene una duración máxima de 30 minutos.',
        faq4header: '¿Qué voy a aprender?',
        faq4message1: 'Se darán pautas generales sobre la correcta convivencia con los perros y su bienestar, así como algunas nociones básicas de entrenamiento canino.',
        faq5header: '¿Quiénes impartirán la masterclass?',
        faq5message1: 'Profesionales de Educación Canina de diferentes entidades con una amplia experiencia en el sector.',
        faq6header: '¿Puedo anular o cambiar una reserva?',
        faq6message1: 'Si necesitas cancelar o modificar una reserva, por favor escríbenos a <a href=\"mailto:ExpertosEnAntiparasitarios@elancoah.com">ExpertosEnAntiparasitarios@elancoah.com</a> confirmaremos la anulación, o bien revisaremos si hay plazas disponibles en otros turnos o ciudades.',
        faq7header: '¿Tienes dudas sobre la masterclass?',
        faq7message1: 'Llámanos al <b><a href=\"tel://617505950\">617 50 59 50</a></b> o envianos un email a <b><a href=\"mailto://hola@perruneando.com\">hola@perruneando.com</a></b> y las intentaremos resolver.',
        recommendation1:'Asistir con ropa y calzado cómodos.',
        recommendation2: 'Opcional: Llevar una bolsa de premios o riñonera con algún tipo de recompensa que le pueda gustar a tu perro.',
        obligation1: 'Los perros deben estar acompañados en todo momento por su tutor/a.',
        obligation2: 'Los perros deben ser conducidos por sus tutores con elementos de paseo cómodos y adecuados que no ocasionen ningún tipo de lesión al perro. ',
        obligation3: 'Los collares de estrangulamiento, pinchos, castigo, etc. no estarán permitidos.',
        obligation4: 'Cualquier signo de violencia o maltrato hacia cualquiera de los animales presentes en la actividad puede suponer la expulsión de la misma.',

        required_name: "Debe introducir su nombre",
        required_surname: "Debe introducir sus apellidos",
        required_email: "Debe introducir su email",
        required_phone: "Debe introducir su teléfono",
        required_dogName: "Debe introducir el nombre de su perro",
        required_dogWeight: "Debe introducir el peso de su perro",
        required_dogAge: "Debe introducir la edad de su perro",
        required_clinic: "Debe introducir su clínica habitual",
        required_city: "Debe seleccionar la ciudad",
        required_turn_type: "Debe seleccionar el tipo de turno deseado",
        required_turn: "Debe seleccionar el turno deseado",
        
        type1: "Cachorros",
        type0: "Mixto",
        type2: "Senior (>7)",

        unknown_error: "Ha habido un error inesperado.<br>Vuelve ha intentarlo de nuevo más tarde.",

        location_unknown: "Próximamente se confirmará la ubicación"
    }
};

const i18n = new VueI18n({
    locale: 'es', // set locale
    fallbackLocale: 'es', // set fallback locale
    messages, // set locale messages
});


export default i18n;