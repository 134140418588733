<template>
  <field
    ref="container"
    class="select-field"
    :value="selected ? selected.value : null"
    :class="{ selected, 'select-field--dialog': dialog }"
    :focused="focused"
    :rules="rules"
    :validate-on-change="validateOnChange"
    @focus="onFocus"
    @blur="onBlur"
    @mousedown="onMouseDown"
    @touchstart="onTouch"
  >

    <span v-if="selected">
      {{ selected.text }}
    </span>
    <span v-else-if="placeholder && !show && !isTouch" class="placeholder">
      {{ placeholder }}
    </span>

    <input
      ref="input"
      :type="type"
      v-show="autocomplete"
      v-model="search"
      @focus="onFocus"
      @blur="onBlur"
      @keydown="onKeyDown"
    />

    <select
      ref="select"
      v-show="!isTouch && show"
      @mousedown="onMouseDown( $event, true )"
      @change="onChange"
      @blur="onBlur"
      :value="selected ? selected.value : null"
      :style="{ height: selectHeigth + 'px', ...position }"
      multiple
    >
      <option
        v-for="(item,i) in filteredItems"
        :selected="selected === item"
        :value="item.value"
        :key="i"
      >
        {{ item.text }}
      </option>
    </select>

    <div
      v-if="isTouch"
      class="select-field-touch-wrappper"
    >

      <div @touchmove="onTouchMove">

        <input
          ref="input2"
          :type="type"
          v-show="autocomplete"
          v-model="search"
        />

        <div>
          <div
            v-for="(item,i) in filteredItems"
            :class="{ selected: selected === item }"
            :key="i"
            @touchstart="onTouchStartItem"
            @touchend="onTouchEndItem( item )"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>

  </field>
</template>

<script>
import { toArray, normalize } from '@/utils';
import Field from './Field';

export default {
  components: { Field },
  props: {
    value: null,
    type: {
      type: String,
      default: 'text'
    },
    autocomplete: Boolean,
    validateOnChange: Boolean,
    placeholder: String,
    rules: Array,
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      search: '',
      selected: null,
      focused: false,
      show: false,
      isTouch: false,
      isTouchMove: false,
      dialog: false,
      position: {}
    };
  },
  computed: {
    computedItems() {
      return this.items.filter( Boolean ).map( item => {
        return typeof item === 'string'
          ? { text: item, value: item, raw: item }
          : { ...item, raw: String( item.value ) };
      });
    },
    filteredItems() {
      if ( ! this.autocomplete || ! this.search ) return this.computedItems;
      let search = normalize( this.search, true );
      return this.computedItems.filter( i => {
        let v = normalize( i.text, true );
        return v.includes( search );
      })
    },
    selectHeigth() {
      return Math.min( this.filteredItems.length * 50, 200 );
    },
    container() {
      if ( ! this.$refs.container ) return null;
      return this.$refs.container.$el.children[0];
    }
  },
  watch: {
    value: 'set',
    focused: 'open',
    show( value ) {
      if ( ! value ) this.isTouch = false;
      if ( this.autocomplete ) {
        if ( value ) {
          setTimeout(() => this.$refs.input.focus());
        } else {
          this.search = '';
        }
      }
    },
    selected( item ) {
      this.search = '';
      this.$emit( 'input', item ? item.value : undefined );
    },
    isTouch( value ) {
      this.changeOverflow( value );
      value && setTimeout(() => this.$refs.input2.focus());
    }
  },
  methods: {
    set( value ) {
      this.selected = this.filteredItems.find( i => i.raw === String( value ));
      this.show = false;
    },
    onFocus(e) {
      this.focused = true;
      this.show = !this.isTouch;
      this.$emit( 'focus', e );
    },
    onBlur(e) {
      if ( toArray( this.container.children ).includes( e.relatedTarget )) return;
      this.focused = this.show = false;
      this.$emit( 'blur', e );
    },
    onChange(e) {
      this.set( e.target.value );
    },
    onMouseDown( e, isSelect ) {
      if ( this.isTouch ) return;
      isSelect && this.$refs.select.focus();
      this.show = true;
      this.$emit( 'mousedown', e );
    },
    onTouch() {
      if ( this.isTouch ) {
        this.dialog = false;
        setTimeout(() => this.isTouch = false, 200 );
      } else {
        this.isTouch = true;
        setTimeout(() => this.dialog = true );
      }
    },
    onTouchMove() {
      this.isTouchMove = true;
    },
    onTouchStartItem(e) {
      e.stopPropagation();
      this.$nextTick(() => this.$refs.input2.focus());
    },
    onTouchEndItem( item ) {
      if ( ! this.isTouchMove ) {
        this.selected = item;
        this.onTouch();
      }
      this.isTouchMove = false;
    },
    onKeyDown(e) {
      if ( e.which === 8 && ! this.search ) this.selected = null;
      this.$emit('keydown', e );
    },
    open() {

      if ( ! this.focused )
        return this.position = null;

      const box = this.container.getBoundingClientRect();
      const bottom = window.innerHeight - box.top - box.height;

      this.position = {
        left: box.left + 'px',
        width: box.width + 'px',
        top: bottom > this.selectHeigth
          ? (box.top + box.height) + 'px'
          : (box.top - this.selectHeigth) + 'px',
      };
    },
    validate() {
      return this.$refs.container.validate();
    },
    hasError() {
      return this.$refs.container.hasError();
    },
    changeOverflow( value ) {
      [
        document.documentElement,
        document.body,
        document.querySelector('main')
      ].forEach( item => {
        item.style.overflow = value ? 'hidden' : '';
      });
    }
  },
  beforeMount() {
    this.set( this.value );
  },
  mounted() {
    window.addEventListener( 'scroll', this.open );
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.open );
  }
}
</script>

<style>
.select-field {
  position: relative;
  margin-bottom: 25px;
  color: #606060;
}
.select-field input {
  flex: 1 1 auto;
  padding: 0 24px;
  color: #606060;
  min-width: 0;
}
.select-field.selected input {
  padding-left: 5px;
  color: #606060;
}
.select-field select {
  position: fixed;
  top: 100%;
  left: 0;
  right: 0;
  border: 0;
  padding: 0;
  overflow: auto;
  max-height: 200px;
  box-shadow: 0 2px 3px rgba(0,0,0,0.12);
  z-index: 9999;
}
.select-field select:focus {
  border: 0;
  outline: 0;
}
.select-field select > option {
  padding: 10px;
  font-size: 1.5em;
  height: 50px;
  cursor: pointer;
  background-color: #1E71AB;
}
.select-field select > option:hover {
  background-color: #0c3e61;
  color: white;
}
.select-field .field-input-wrappper {
  box-shadow: inset 8px 10px 5px -5px rgba(0,0,0,0.75);
  transition: box-shadow .3s cubic-bezier( 0.25, 0.8, 0.5, 1 );
}
.select-field.field-input > .field-input-wrappper > span {
  flex: 0 0 auto;
  padding-right: 0;
  color: #606060;
}
.select-field.field-input > .field-input-wrappper > span:first-child {
  padding-left: 24px;
  color: #606060;
}
.select-field-touch-wrappper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0);
  transition: background-color .2s ease-in-out;
  z-index: 9999;
}
.select-field-touch-wrappper input {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 1px;
  height: 60px;
  font-size: 20px;
  transform: translateY(100%);
  transition: transform .2s ease-in-out;
}
.select-field-touch-wrappper input:focus {
  outline: 0;
}
.select-field-touch-wrappper > div {
  list-style: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 0px;
  padding: 0;
  margin: 0;
  background-color: white;
  border-radius: 4px 4px 0 0;
  transition: max-height .2s ease-in-out;
}
.select-field-touch-wrappper > div > div {
  overflow-y: auto;
}
.select-field.select-field--dialog .select-field-touch-wrappper {
  background-color: rgba(0,0,0,.12);
}
.select-field.select-field--dialog .select-field-touch-wrappper input {
  transform: translateY(0);
}
.select-field.select-field--dialog .select-field-touch-wrappper > div,
.select-field.select-field--dialog .select-field-touch-wrappper > div > div {
  max-height: 50vh;
}
.select-field-touch-wrappper > div > div > div {
  color: #606060;
  border-top: 1px solid rgba(0,0,0,0.12);
  padding: 24px;
}
.select-field-touch-wrappper > div > div > div.selected {
  background-color: rgba(0,0,0,.12);
}
.select-field-touch-wrappper > div > div > div:first-child {
  border-top: 0;
}
</style>
