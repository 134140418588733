<template>
  <div id="place-view">
    <div class="container">
      <div
        class="placeContainer"
        v-for="item in places"
        v-bind:key="item.placeholder1"
        @click="onPlaceClicked(item)"
      >
        <div style="width: 70%">
          <p class="placeHeader">{{ item.name }}</p>
          <p class="placeInfo" >{{ item.street!=""?item.street : $t('location_unknown') }}</p>
          <p class="placeInfo">{{ item.postalCode }}</p>
        </div>
        <p class="iconLocation" >
          <img src="@/assets/Icon_Location.svg" /><br /> {{ $t('directions')}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from "@/components/Btn";

function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export default {
  data: () => ({
    start: 0,
    end: 0,
    interval: 9,
    current: 1,
    total: [],
  }),

  mounted() {
    this.end = this.interval;

    let counter = 1;
    this.total.push(counter);
    for (let i = 0; i < this.places.length; i = i + this.end) {
      if (i + this.end < this.places.length) {
        counter++;
        this.total.push(counter);
      }
    }
  },
  computed: {
    places() {
      var places = this.$store.state.data.map((it) => it.place);

      const key = "id";

      const distinctPlaces = [
        ...new Map(places.map((item) => [item[key], item])).values(),
      ];

      distinctPlaces.sort(compare);

      return distinctPlaces;
    },
  },
  components: { Btn },
  methods: {
    reload() {
      window.location.reload();
    },
    selectPage(index) {
      this.toRender = [];
      if (this.current < index) {
        for (let i = this.current; i < index; i++) {
          this.start = this.end;
          this.end = this.end + this.interval;
        }
      } else {
        for (let i = this.current; i > index; i--) {
          this.end = this.start;
          this.start = this.start - this.interval;
        }
      }
      this.current = index;
      this.loopPages();
    },
    onPlaceClicked(item) {
      var href = null;
      if (item.googleMaps != undefined) {
        href = item.googleMaps;
      } else if (
        item.latitude != undefined &&
        item.longitude != undefined &&
        item.latitude != 0 &&
        item.longitude != 0
      ) {
        href =
          "https://maps.google.com/?q=" + item.latitude + "," + item.longitude;
      } else {
        href = "https://maps.google.com/?q=" + item.name.split(/[0-9]/)[0] + item.street;
      }
      window.open(href, "blank");
    },
  },
};
</script>

<style>
* {
  color: white;
}

.container {
  margin: auto;
}

.placeContainer {
  position: relative;
  padding: 20px;
  border-bottom: 0.5px solid white;
  min-height: 100px;
}

.placeContainer:hover{
  cursor: pointer;
  background: rgba(1, 1, 1, 0.5);
}

.placeContainer:first-of-type {
  border-top: 0.5px solid white;
}

.placeHeader {
  font-size: 1em;
  font-weight: 700;
}

.placeInfo {
  font-size: 0.9em;
}

.iconLocation {
  position: absolute;
  text-align: center;
  font-weight: bold;
  font-size: 1.3em;
  top: 0;
  right: -20px;
  transform: scale(0.6, 0.6);
}

.listContainer {
  display: flex;
  padding-top: 40px;
  justify-content: center;
}

.listContainer div {
  display: inline-block;
  height: 40px;
  padding: 0 10px 0 10px;
  border-right: 4px solid white;
}

.listContainer div p {
  font-size: 30px;
  vertical-align: middle;
}

.listContainer a {
  cursor: pointer;
}

.listContainer div:last-child {
  border: 0;
}

.pageSelected {
  font-weight: bold;
  font-size: 38px;
}
</style>