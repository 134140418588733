import Vue from 'vue';
import Vuex from 'vuex';

// AXIOS CONFIG

import axios from 'axios';
import { API_URL } from '@/utils/constants';

const request = axios.create({
  baseURL: API_URL,
  headers: { 'Content-type': 'application/json' }
});

Vue.use( Vuex );

let alertCount = 0;
const store = new Vuex.Store({
  state: {
    alerts: [],
    data: []
  },
  mutations: {
    set( state, payload ) {
      for ( var key in payload ) {
        state[key] = payload[key];
      }
    },
    addAlert( state, alert ) {
      state.alerts.push( alert );
    },
    removeAlert( state, alert ) {
      var index = state.alerts.indexOf( alert );
      if ( index !== -1 ) {
        state.alerts.splice( index, 1 );
      }
    }
  },
  actions: {
    participate( ctx, payload ) {

      return request.post( '/participate', payload )
        .then( res => res.data );
    },
    getData() {
      return request.get( '/data' )
        .then( res => res.data );
    },
    isActive() {
      return request.get( '/active' )
        .then( res => res.data );
    },
    alert({ commit }, alert ) {

      alert = {
        id: alertCount++,
        color: 'black',
        message: '',
        permanent: false,
        timeout: 5000,
        ...alert
      };

      alert.remove = () => {
        clearInterval( alert.interval );
        commit( 'removeAlert', alert );
      };

      commit( 'addAlert', alert );

      if ( ! alert.permanent ) {
        alert.interval = setTimeout( alert.remove, alert.timeout );
      }

      return alert;
    }
  }
});

export default store;
