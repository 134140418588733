<template>
  <div id="finalized-view">
    <div class="container">

      <img
        style="margin-bottom:48px"
        :src="require('@/assets/menu-icon.png')"
        :srcset="require('@/assets/menu-icon.svg')"
        alt="Icono menú"
        width="55"
        height="33"
        v-if="false"
      />

      <h1 style="margin-bottom:40px;" v-html="$t('waitingListTitle')">
      </h1>

      <p class="info mb-5" v-html="$t('waitingListMessage')">
      </p>

      <div>
        <btn
          color="white"
          width="300" height="60"
          @click="reload"
        >
        {{ $t('close')}}
        </btn>
      </div>

    </div>
  </div>
</template>

<script>
import Btn from '@/components/Btn';
export default {
  components: { Btn },
  methods: {
    reload() {
      window.location.reload();
    }
  }
}
</script>
<style scoped>
.info {
  font-size: 1.2em !important;
}
</style>