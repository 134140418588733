<template>
  <main :class="classes">
    <transition name="fade" mode="out-in">
      <div>
        <div
          v-if="loading"
          key="loading"
          class="fixed layer row align-center justify-center"
          style="bottom: 153px"
        >
          <spinner color="white" loading />
        </div>
        <div style="container" v-if="!loading">
          <customHeader> </customHeader>

          <nav v-if="!success && !waitingList">
            <btn
              width="150"
              height="60"
              v-on:click="
                (formSelected = true),
                  (faqSelected = false),
                  (placeSelected = false)
              "
              :class="{ btnSelected: formSelected }"
              v-html="$t('tab1')"
            >
            </btn>

            <btn
              width="150"
              height="60"
              v-on:click="
                (faqSelected = true),
                  (formSelected = false),
                  (placeSelected = false)
              "
              :class="{ btnSelected: faqSelected }"
              v-html="$t('tab2')"
            >
            </btn>

            <btn
              width="150"
              height="60"
              v-on:click="
                (placeSelected = true),
                  (formSelected = false),
                  (faqSelected = false)
              "
              :class="{ btnSelected: placeSelected }"
              v-html="$t('tab3')"
            >
            </btn>
          </nav>

          <form-view
            v-if="formSelected && !success && !waitingList"
            @success="success = true"
            @waitingList="waitingList = true"
            key="formSelected"
          />

          <faq-view
            v-if="faqSelected && !success && !waitingList"
            key="faqSelected"
          />

          <place-view
            v-else-if="placeSelected && !success && !waitingList"
            key="placeSelected" />

          <success-view 
          v-else-if="success" 
          key="success" />

          <waiting-list-view 
          v-else-if="waitingList" 
          key="waitingList" />

          <customFooter/>
        </div>
      </div>
    </transition>
  </main>
</template>

<script>
import Spinner from "vue-spinner/src/PulseLoader.vue";
import faqView from "@/views/FAQView";
import formView from "@/views/FormView";
import placeView from "@/views/PlaceView";
import successView from "@/views/SuccessView";
import waitingListView from "@/views/WaitingListView";
import customHeader from "@/components/Header";
import customFooter from "@/components/Footer";
import Btn from "@/components/Btn";

export default {
  name: "App",
  components: {
    Spinner,
    formView,
    faqView,
    placeView,
    successView,
    waitingListView,
    customHeader,
    customFooter,
    Btn,
  },
  data: () => ({
    formSelected: true,
    faqSelected: false,
    placeSelected: false,
    loading: true,
    success: false,
    waitingList: false,
    error: null,
    innerWidth: 0,
    innerHeight: 0,
    publicPath: process.env.BASE_URL
  }),
  computed: {
    classes() {
      return {
        "is-mobile": this.isMobile,
        [this.size]: 1,
      };
    },
    size() {
      if (this.innerHeight) {
        if (this.innerHeight < 560) return "size--x-small";
        if (this.innerHeight < 722) return "size--small";
        if (this.innerHeight < 900) return "size--medium";
      }
      return "size--default";
    },
    isMobile() {
      return this.innerWidth < 870;
    }
  },
  methods: {
    onResize() {
      this.innerWidth = window.innerWidth;
      this.innerHeight = window.innerHeight;
    },
  },
  mounted() {
    this.$store
      .dispatch("getData")
      .then((res) => {
        this.finalized = res.error < 0;
        //guardamos datos en el store de places...
        this.$store.state.data = res.object;
      })
      .catch((err) => {
        this.error = err;
      })
      .finally(() => {
        this.loading = false;
      });

    window.addEventListener( 'resize', this.onResize );
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener( 'resize', this.onResize );
  },
};
</script>

<style>

main.size--default {
  zoom: 1;
}
main.size--medium {
  zoom: 0.79;
}
main.size--small,
main.is-mobile {
  zoom: 0.65;
}
main.size--x-small {
  zoom: 0.6;
}

nav {
  display: flex;
  justify-content: center;
}

footer {
  padding: 0 90px 20px;
  margin-top: 40px;
  z-index: 10;
  justify-content: flex-end;
}

main.is-mobile footer {
  position: relative;
  height: 250px;
  padding: 0 20px;
  justify-content: center;
}
</style>
