<template>
  <TransitionGroup
    :class="classes"
    :style="{ zIndex }"
    name="slide-y"
    tag="div"
  >
    <div
      v-for="alert in alerts"
      :key="alert.id"
    >
      <alert
        class="mb-1"
        :value="alert.show"
        :timeout="alert.persistent ? 0 : ( alert.timeout || timeout )"
        :color="alert.color"
        style="max-width:720px"
        @input="removeAlert( alert )"
      >
        <div v-html="alert.message"/>
      </alert>
    </div>
  </TransitionGroup>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Alert from './Alert';

export default {
  components: { Alert },
  props: {
    timeout: {
      type: [ Number, String ],
      default: 5000
    },
    absolute: Boolean,
    fixed: Boolean,
    zIndex: {
      type: [ Number, String ],
      default: 5
    }
  },
  methods: mapMutations([ 'removeAlert' ]),
  computed: {
    ...mapState([ 'alerts' ]),
    classes() {
      return {
        'alerts-group': 1,
        'absolute': this.absolute,
        'fixed': this.fixed
      }
    }
  }
};
</script>

<style>
.alerts-group {
  padding: 8px;
  text-align: center;
}
.alerts-group.absolute,
.alerts-group.fixed {
  top: 0;
  left: 0;
  right: 0;
}
.alerts-group .alert {
  display: inline-block;
  text-align: left;
}
</style>
