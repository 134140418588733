<template>
  <div id="faq-view">
    <div class="container">
      <h3 class="faqs listHeader">{{ $t('faq1mainheader')}}</h3>
      <ol>
        <div
          class="liContainer"
          v-for="(item, index) in faq"
          v-bind:key="index"
        >
          <li class="liHeader">
            <div v-html="item.header"></div>
            <ul>
              <li
                v-for="(tip, index) in item.tips"
                v-bind:key="index"
                v-html="tip.message"
              ></li>
            </ul>
          </li>
        </div>
      </ol>

      <h3 class="recommendations listHeader">{{ $t('faq2mainheader')}}</h3>
      <ul>
        <div
          class="liContainer"
          v-for="(item, index) in recomendations"
          v-bind:key="index"
        >
          <li class="liMessage" v-html="item.message"></li>
        </div>
      </ul>

      <h3 class="obligations listHeader">{{ $t('faq3mainheader')}}</h3>
      <ul>
        <div
          class="liContainer"
          v-for="(item, index) in obligations"
          v-bind:key="index"
        >
          <li class="liMessage" v-html="item.message"></li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import Btn from "@/components/Btn";
export default {
  data: () => ({
    faq: [
    ],
    recomendations: [
    ],
    obligations: [
    ],
  }),
  components: { Btn },
  mounted() {
    this.reloadData()
  },
  methods: {
    reload() {
      window.location.reload();
    },
    reloadData() {
      this.faq= [
      {
        header: this.$t("faq1header"),
        tips: [
          { message: this.$t("faq1message1") },
          { message: this.$t("faq1message2") },
          { message: this.$t("faq1message3") },
          { message: this.$t("faq1message4") },
        ],
      },
      {
        header: this.$t("faq2header"),
        tips: [
          { message: this.$t("faq2message1") },
          { message: this.$t("faq2message2") },
        ],
      },
      {
        header: this.$t("faq3header"),
        tips: [{ message: this.$t("faq3message1") }],
      },

      {
        header: this.$t("faq4header"),
        tips: [{ message: this.$t("faq4message1") }],
      },

      {
        header: this.$t("faq5header"),
        tips: [{ message: this.$t("faq5message1") }],
      },
      {
        header: this.$t("faq6header"),
        tips: [{ message: this.$t("faq6message1") }],
      },
      {
        header: this.$t("faq7header"),
        tips: [{ message: this.$t("faq7message1") }],
      },
    ],
    this.recomendations= [
      { message: this.$t("recommendation1") },
      { message: this.$t("recommendation2") },
    ],
    this.obligations= [
      { message: this.$t("obligation1") },
      { message: this.$t("obligation2") },
      { message: this.$t("obligation3") },
      { message: this.$t("obligation4") },
    ]
    },
  },
};
</script>

<style>
* {
  color: white;
}

.container {
  margin: auto;
}

.listHeader {
  margin-bottom: 0px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid white;
  font-size: 30px !important;
}

.recommendations,
.obligations {
  margin-top: 60px;
}

ol:first-child {
  border-top: 0.5px solid white;
}

ol div {
  padding-top: 10px;
}

.liContainer {
  border-bottom: 0.5px solid white;
}

.liHeader {
  margin-left: 1em;
}

ol ul {
  padding-bottom: 15px;
}

li {
  font-size: 22px;
  font-weight: bold;
}

li li,
.liMessage {
  font-size: 24px;
  list-style-type: "-  ";
  font-weight: normal;
}

.liMessage {
  margin: 10px 0 10px 1em;
}
</style>
