const path = require('path');
const m = {};

const BASE_URL = "https://roadshow.assaicomunicacion.com";
//const BASE_URL = "https://roadshow.pt.assaicomunicacion.com";

m.IS_PROD = process.env.NODE_ENV == 'production';
m.DEBUG = !m.IS_PROD;
m.PORT = process.env.PORT || 3232;
m.BASE_URL = m.IS_PROD
  ? process.env.BASE_URL
  : `http://localhost:${m.PORT}`;

// API constants

m.API_BASE = m.IS_PROD
  ? '/api'
  : '/pre';

m.PROXY = {
  [m.API_BASE]: {
    target: BASE_URL,
    pathRewrite: { '^/pre': 'api' },
    changeOrigin: true,
    secure: false
  }
};

m.API_URL = m.IS_PROD
  ? path.join( 'api' )
  : m.API_BASE;

module.exports = m;
