<template>
  <field
    ref="field"
    class="file-field"
    :value="file"
    :focused="focused"
    :rules="rules"
    :validate-on-change="validateOnChange"
    @click="onClick"
  >

    <btn class="small" style="flex: 0 0 auto" dark>
      Sube tu archivo
    </btn>

    <span v-if="file">
      {{ file.name }}
    </span>
    <span v-else-if="placeholder" class="placeholder">
      {{ placeholder }}
    </span>

    <input
      ref="input"
      type="file"
      v-show="false"
      v-bind="$attrs"
      @change="onChange"
      @focus="focused = true"
      @blur="focused = false"
    />

  </field>
</template>

<script>
import Btn from '@/components/Btn';
import Field from './Field';

export default {
  components: { Field, Btn },
  props: {
    value: null,
    placeholder: String,
    validateOnChange: Boolean,
    rules: Array
  },
  data() {
    return {
      focused: false,
      file: this.value
    };
  },
  watch: {
    file( file ) {
      this.$emit( 'input', file );
    }
  },
  methods: {
    onClick() {
      this.$refs.input.click();
    },
    onChange(e) {
      this.file = e.target.files[0];
    },
    validate() {
      return this.$refs.field.validate();
    },
    hasError() {
      return this.$refs.field.hasError();
    }
  }
}
</script>

<style>
.file-field {
  min-width: 300px;
}
.file-field .btn {
  height: 32px;
  border-radius: inherit;
  margin: 0 2px;
}
.file-field.field-input > .field-input-wrappper > span {
  padding: 0 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1 auto;
}
</style>
