export function toArray( value ) {
  if ( value == null ) return [];
  if ( typeof value !== 'string' && value.length != null ) {
    return Array.prototype.slice.call( value );
  }
  return [ value ];
}

export function convertToUnit( str, unit = 'px' ) {
  if ( str == null || str === '' ) {
    return undefined;
  } else if ( isNaN(+str)) {
    return String(str);
  } else {
    return `${Number(str)}${unit}`;
  }
}

const from  = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç";
const to    = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc";
const map   = {};

for( var i = 0, j = from.length; i < j; i++ )
    map[ from.charAt( i ) ] = to.charAt( i );

export function normalize( str, lower ) {
  var ret = [];
  for( var i = 0, j = str.length; i < j; i++ ) {
    var c = str.charAt( i );
    if ( map.hasOwnProperty( c )) ret.push( map[ c ] );
    else ret.push( c );
  }
  if ( lower ) return ret.join( '' ).toLowerCase();
  return ret.join( '' );
}
