<template>
  <field
    ref="field"
    class="text-field"
    :value="value"
    :focused="focused"
    :rules="rules"
    :validate-on-change="validateOnChange"
  >
    <input
      v-bind="$attrs"
      :type="type"
      :value="value"
      @input="$emit( 'input', $event.target.value.trim() || undefined )"
      @focus="focused = true"
      @blur="focused = false"
    />
  </field>
</template>

<script>
import Field from './Field';

export default {
  components: { Field },
  props: {
    value: null,
    rules: Array,
    validateOnChange: Boolean,
    type: {
      type: String,
      default: 'text'
    }
  },
  data() {
    return {
      focused: false
    }
  },
  methods: {
    validate() {
      return this.$refs.field.validate();
    },
    hasError() {
      return this.$refs.field.hasError();
    }
  }
}
</script>

<style>

.text-field {
  border-radius: 0;
  margin-bottom: 35px;
}

.text-field input {
  flex: 1 1 100%;
  width: 100%;
  display: block;
  padding: 0 24px;
  box-shadow: inset 8px 10px 5px -5px rgba(0,0,0,0.75);
  transition: box-shadow .3s cubic-bezier( 0.25, 0.8, 0.5, 1 );
}
.text-field input[type="number"] {
  padding-right: 12px;
}
</style>
