<template>
  <div id="form-view">
    <alerts fixed />

    <form id="form" @submit.prevent="onSubmit">
      <div class="row mb-3">
        <div class="formContainer">

          <text-field
            ref="name"
            id="name"
            :value="form.name"
            :rules="rules.name"
            :validate-on-change="validateOnChange"
            @input="onInput('name', $event)"
            style="margin-left: 0; flex: 0 0 232px; width: 100%"
            :placeholder="$t('name')"
          />

          <text-field
            ref="surname"
            id="surname"
            :value="form.surname"
            :rules="rules.surname"
            :validate-on-change="validateOnChange"
            @input="onInput('surname', $event)"
            style="margin-left: 0; flex: 0 0 232px; width: 100%"
            :placeholder="$t('surname')"
          />

          <text-field
            ref="email"
            id="email"
            :value="form.email"
            :rules="rules.email"
            :validate-on-change="validateOnChange"
            @input="onInput('email', $event)"
            style="margin-left: 0; flex: 0 0 232px; width: 100%"
            :placeholder="$t('email')"
          />

          <text-field
            ref="phone"
            id="phone"
            :value="form.phone"
            :rules="rules.phone"
            :validate-on-change="validateOnChange"
            @input="onInput('phone', $event)"
            style="margin-left: 0; flex: 0 0 232px; width: 100%"
            :placeholder="$t('phone')"
          />

          <text-field
            ref="dogName"
            id="dogName"
            :value="form.dogName"
            :rules="rules.dogName"
            :validate-on-change="validateOnChange"
            @input="onInput('dogName', $event)"
            style="margin-left: 0; flex: 0 0 232px; width: 100%"
            :placeholder="$t('dogName')"
          />

          <text-field
            ref="dogAge"
            id="dogAge"
            :value="form.dogAge"
            :rules="rules.dogAge"
            :validate-on-change="validateOnChange"
            @input="onInput('dogAge', $event)"
            style="margin-left: 0; flex: 0 0 232px; width: 100%"
            :placeholder="$t('dogAge')"
          />

          <text-field
            ref="dogWeight"
            id="dogWeight"
            :value="form.dogWeight"
            :rules="rules.dogWeight"
            :validate-on-change="validateOnChange"
            @input="onInput('dogWeight', $event)"
            style="margin-left: 0; flex: 0 0 232px; width: 100%"
            :placeholder="$t('dogWeight')"
          />

          <text-field
            ref="clinic"
            id="clinic"
            :value="form.clinic"
            :rules="rules.clinic"
            :validate-on-change="validateOnChange"
            @input="onInput('clinic', $event)"
            style="margin-left: 0; flex: 0 0 232px; width: 100%"
            :placeholder="$t('clinic')"
          />

          <select-field
            ref="selectedCity"
            id="selectedCity"
            :value="form.city"
            :rules="rules.city"
            :items="cities"
            :validate-on-change="validateOnChange"
            @input="onInput('city', $event)"
            style="margin-left: 0; flex: 0 0 232px; width: 100%; margin-bottom: 35px;"
            :placeholder="$t('city')"
            autocomplete
          />

          <select-field
            ref="type"
            id="type"
            v-if="types.length > 0"
            :value="form.type"
            :rules="rules.type"
            :items="types"
            :validate-on-change="validateOnChange"
            @input="onInput('type', $event)"
            style="margin-left: 0; flex: 0 0 232px; width: 100%; margin-bottom: 35px;"
            :placeholder="$t('turnType')"
            autocomplete
          />

          <select-field
            ref="turn"
            id="turn"
            v-if="turns.length > 0"
            :value="form.turn"
            :rules="rules.turn"
            :items="turns"
            :validate-on-change="validateOnChange"
            @input="onInput('turn', $event)"
            style="margin-left: 0; flex: 0 0 232px; width: 100%;"
            :placeholder="$t('turn')"
            autocomplete
          />

          <p class="turnInfo" v-html="$t('turnInfo')"></p>
          <br />

          <p class="privacy" v-html="$t('privacy')"></p>

          <btn class="buttonReserve" @click="onSubmit" v-html="btnReserveText">
          </btn>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Alerts from "@/components/Alerts";
import Spinner from "vue-spinner/src/PulseLoader.vue";
import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import FileField from "@/components/FileField";
import Checkbox from "@/components/Checkbox";
import Btn from "@/components/Btn";

const required = (msg) => (v) => !!(v || v === 0) || msg;
const validateEmail =  (msg) => (v) =>  (!!(v || v === 0) && v.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)) || msg;


function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export default {
  components: {
    Alerts,
    Spinner,
    TextField,
    SelectField,
    FileField,
    Checkbox,
    Btn,
  },
  data: () => ({
    validateOnChange: false,
    loading: false,
    form: {
      name: undefined,
      surname: undefined,
      email: undefined,
      phone: undefined,
      dogName: undefined,
      dogWeight: undefined,
      dogAge: undefined,
      clinic: undefined,
      city: undefined,
      type: -1,
      turn: undefined,
    },
    rules: {},
    types: {},
  }),
  watch: {
    'form.city': function (newVal, oldVal){
          this.form.turn = -1;

     },
    'form.type': function (newVal, oldVal){
          this.form.turn = -1;

     },
  },
  mounted() {
    this.rules = {
      name: [required(this.$t("required_name"))],
      surname: [required(this.$t("required_surname"))],
      email: [validateEmail(this.$t("required_email"))],
      phone: [required(this.$t("required_phone"))],
      dogName: [required(this.$t("required_dogName"))],
      dogWeight: [required(this.$t("required_dogWeight"))],
      dogAge: [required(this.$t("required_dogAge"))],
      clinic: [required(this.$t("required_clinic"))],
      city: [required(this.$t("required_city"))],
      turn: [required(this.$t("required_turn"))],
      type: [required(this.$t("required_turn_type"))],
    };

    this.types = [
      { text: this.$t("type0"), value: 0 },
      { text: this.$t("type1"), value: 1 },
      { text: this.$t("type2"), value: 2 },
    ];
  },
  computed: {
    btnReserveText() {
      if (this.form.turn != undefined) {
        var listTurns = this.$store.state.data.filter(
          (it) => it.id == this.form.turn
        );
        if (listTurns.length > 0 && listTurns[0].numPlaces <= 0) {
          return this.$t("btnWaiting");
        }
      }
      return this.$t("reserve");
    },
    cities() {
      var turns = this.$store.state.data;
      var places = turns.map((it) => it.place);

      const key = "id";

      const distinctPlaces = [
        ...new Map(places.map((item) => [item[key], item])).values(),
      ];

      //por cada ciudad añadimos la fecha al nombre

      /*var finalPlaces = [];

      distinctPlaces.forEach((place) => {
        const key = "day";

        const times = [
          ...new Map(
            turns
              .filter((it) => it.place.id == place.id)
              .map((item) => [
                item[key],
                item
              ])
          ).values(),
        ];

        times.forEach((t) => {
          var day = t.day + "/" + t.month + "/" + t.year
          var p = { ...place };
          p.name = p.name + " " + day;
          p.turn = t;
          finalPlaces.push(p);
        });
      });*/

      distinctPlaces.sort(compare);

      return distinctPlaces.map((it) => {
        return { text: it.name, value: it.id };
      });
    },
    turns() {
      if (this.form.type >= 0 && this.form.city) {
        var turns = this.$store.state.data.filter(
          (it) => it.place.id == this.form.city && it.type == this.form.type
        );
        return turns.map((it) => {
          return { text: this.processTurnName(it), value: it.id };
        });
      }
      return [];
      /*return this.$store.state.data.map(it=> {
        return {text: this.processTurnName(it), value: it.id}
      });*/
    },
  },
  methods: {
    processTurnName(it) {
      var name = it.timetable;
      if (it.numPlaces <= 0) {
        name = name + " (" + this.$t("placesWaitingList") + ")";
      } else if (it.numPlaces == 1) {
        name = name + " (" + it.numPlaces + " " + this.$t("place") + ")";
      } else {
        name = name + " (" + it.numPlaces + " " + this.$t("places") + ")";
      }
      return name;
    },
    onSubmit() {
      if (this.validate()) {
        this.loading = true;
        this.$store
          .dispatch("participate", this.form)
          .then((r) => {
            if (r.object.status == 0) {
              this.$emit("waitingList");
            } else {
              this.$emit("success");
            }
          })
          .catch((err) => {
            console.error(err);
            this.$store.dispatch("alert", {
              color: "#f85050",
              message: this.$t("unknown_error"),
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    onInput( field, value ) {

      if (( field == "city" || field == "type" )) {
        this.onInput( 'turn', undefined );
      }

      this.$set( this.form, field, value );
      this.$refs[field] && this.$refs[field].$el.classList.remove("error");
    },
    cleanErrors() {},
    validate() {
      let valid = Object.values(this.$refs)
        .filter((vn) => vn && vn.validate)
        .reduce((res, vn) => vn.validate() && res, true);

      this.validateOnChange = true;
      return valid;
    },
  },
};
</script>

<style>
.view-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  max-width: 1366px;
  text-align: right;
  padding: 0 100px;
}
.view-label > h2 {
  display: inline-block;
  padding: 40px 30px 40px;
  -webkit-border-radius: 0 0 30px 30px;
  border-radius: 0 0 30px 30px;
  background-color: #c8dcd9;
  text-align: center;
}

#form-view .container > p {
  max-width: 1000px;
}
#form > .row {
  margin-top: 20px;
}

.formContainer {
  width: 70%;
  margin: 40px auto 0;
  margin-top: 40px;
  max-width: 1000px;
}

.field label {
  font-weight: 600;
  margin-bottom: 12px;
}

.buttonReserve {
  width: 100%;
  height: 60px;
  color: white;
  font-size: 1.2em;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 40px;
}

.privacy {
  position: relative;
  left: 5%;
  width: 90%;
  color: white;
  text-align: left;
  font-size: 0.7em;
}

.turnInfo {
  position: relative;
  left: 5%;
  width: 90%;
  color: white;
  font-size: 0.8em;
  text-align: left;
  line-height: 20px;
  padding-top: 20px;
  
}

.btn-submit {
  margin-right: 24px;
}
.is-mobile .view-label {
  padding: 0 0px;
  margin-top: 20px;
}

.is-mobile .view-label > h2 {
  -webkit-border-radius: 30px 0 0 30px;
  border-radius: 30px 0 0 30px;
  padding: 30px 30px 30px;
}

.is-mobile #form-view .container > p,
.is-mobile #form-view .container > h1,
.is-mobile #form,
.is-mobile #form .field > .field-input,
.is-mobile #form .field > .row,
.is-mobile #form .field > ul,
.field-info {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.is-mobile .field-info {
  display: none;
}
.is-mobile #form .field > ul {
  max-width: 430px;
}
.is-mobile #form .field > .row {
  max-width: 480px;
}
.field-info {
  max-width: 475px;
  text-align: left;
  font-size: 20px;
  margin-bottom: 12px;
}
.is-mobile #form > .row {
  justify-content: center;
}
.is-mobile #form > .row > .btn {
  margin-bottom: 12px;
}
.is-mobile #form > .row > .spacer {
  flex: 0 0 100%;
}
.is-mobile #form > .row p:last-child {
  font-size: 0.8em;
}
#ticketFile .field-input-hint,
#numArticles .field-input-hint,
#bizum .field-input-hint {
  white-space: nowrap;
}
@media (max-width: 352px) {
  #form .field > .row > .field-input {
    flex: 0 0 100% !important;
    margin-bottom: 12px;
  }
}
</style>
